import {Alarm, AnomalyDetectionResult, AnomalyGraphDataMap, Device, ModelTemplate} from "../../types/dataTypes";
import Plot, {PlotParams} from "react-plotly.js";
import { Data, PlotMouseEvent, Layout, PlotData, PlotRelayoutEvent } from "plotly.js"
import RangeAwarePlot from "../RangeAwarePlot";
import {useEffect, useState} from "react";
import Button from "../Button";
import SimpleDateSelect from "./Components";
import {SimpleGraphDates, simpleGraphTimeConverter} from "./Components/SimpleDateSelect";
import * as Plotly from "plotly.js";
import {useNavigate} from "react-router";
import {createPreviousAlarmXYAndText, formatAlarmText, formatDate} from "./AnomalyGraphHelpers";


type AnomalyGraphProps = {
  device: Device
  modelTemplates: ModelTemplate[]
  anomalyGraphDataMap: AnomalyGraphDataMap
  alarms: Alarm[]
  dataGapDist?: number
  onGraphClick?: (e: Readonly<PlotMouseEvent>) => void
  hovermode?: Layout["hovermode"]
  className?: string
  selectedGraphTime?: SimpleGraphDates
  onUpdateGraphTime?: (selectedTime: SimpleGraphDates) => void
  graphEndTime?: Date
  navigateToAlarmOnClick?: boolean
  showProbability?: boolean  // Wether to include probability in graph
  probabilityVisible?: boolean // Wether to show probability in graph if included
  sortModels?: (arr: ModelTemplate[]) => ModelTemplate[], // function to filter and/or reorder shown models
  addCustomPlotData?: (plotData: Data[], yLowerRange: number, yUpperRange: number) => void
  addCustomPlotLayout?: (plotLayout: Partial<Layout>, yLowerRange: number, yUpperRange: number) => void
  loading?: boolean
}

type RangeTouple = {
  xAxisRange: any[] | undefined
  yAxisRange: any[] | undefined
}

function mapFeatureToCustomerFacing(feature: string): string {
  return {
    "leq": "Soundlevel",
    "vib_mag": "Vibration"
  }[feature] ?? feature
}

function createDiscontinousGraphWithFill(dataX: (string | null)[], dataY: (number | null) [], plotData: PlotData): PlotData[]{
  // Needed because Plotly has bug with fills, not fixed for long time: https://github.com/plotly/plotly.js/issues/2736
  type ContData = {
    dataX: (string | null)[]
    dataY: (number | null)[]
  }

  if (dataX.length !== dataY.length){
    console.log("Data X and Data Y not same length, skipping")
    return []
  }

  let continousSeries : ContData[] = []
  let startCounter = -1
  let endCounter = -1

  for( let i = 0; i < dataX.length; i++){

    if(typeof dataY[i] === 'number' && startCounter === -1){
      startCounter = i
    }
    if(typeof dataY[i] !== 'number' && startCounter !== -1){
      let newContData: ContData = {
        dataX: dataX.slice(startCounter, i),
        dataY: dataY.slice(startCounter, i)
      }
      continousSeries.push(newContData)
      startCounter = -1
    }
    //Skip continous periods of nulls
  }

  if (startCounter !== -1){
    //Add last period if not ending with nulls
    let newContData: ContData = {
        dataX: dataX.slice(startCounter, -1),
        dataY: dataY.slice(startCounter, -1)
      }
      continousSeries.push(newContData)
  }

  let showLegend = plotData.showlegend

  return continousSeries.map( series => {
    let data = (JSON.parse(JSON.stringify(plotData)) as PlotData)
    data.x = series.dataX
    data.y = series.dataY
    data.showlegend = showLegend
    showLegend = false //If we should show legend, we should show only for first graph
    return data
  } )
}

function defaultModelSortCompare(a : ModelTemplate, b : ModelTemplate) {

    if (a.name.toLowerCase().includes('conditional')) {
        // Push to end
        return 1;
    }
    if (b.name.toLowerCase().includes('conditional')) {
        // Push to end
        return -1;
    }

    return a.name.localeCompare(b.name)

}

export function defaultModelSort(arr: ModelTemplate[]) : ModelTemplate[] {

    let out = Array.from(arr)
    out.sort(defaultModelSortCompare)
    return out
}


export function AnomalyGraph({
                               device,
                               modelTemplates,
                               anomalyGraphDataMap,
                               alarms,
                               dataGapDist = 60*60,
                               hovermode = "closest",
                               onGraphClick,
                               className,
                               selectedGraphTime,
                               onUpdateGraphTime,
                               graphEndTime,
                               navigateToAlarmOnClick = true,
                               showProbability = false,
                               probabilityVisible = false,
                               sortModels = undefined,
                               addCustomPlotData = undefined,
                               addCustomPlotLayout = undefined,
                               loading=false
                             }: AnomalyGraphProps) {

  const navigate = useNavigate()

  const [plots, setPlots] = useState<{ [modelName: string]: JSX.Element }>()
  const [selectedModel, setSelectedModel] = useState<string>()
  const [modelNames, setModelNames] = useState<Set<string>>()

  //Graph Axis Controll states
  const [axisRange, setAxisRange] = useState<RangeTouple | undefined>(undefined);
  const [originalAxisRange, setOriginalAxisRange] = useState<{[key: string] : RangeTouple}>({});


  if (sortModels === undefined) {
     sortModels = defaultModelSort;
  }

  useEffect(() => {
    // Find all groups of model templates
    if (modelTemplates.length > 0 &&  modelTemplates.every(mt => Object.keys(anomalyGraphDataMap).includes(mt.id))) {
      let modelTemplatesWithResults: ModelTemplate[] = modelTemplates.filter(mt => anomalyGraphDataMap[mt.id].length > 0)

      modelTemplatesWithResults = sortModels!(modelTemplatesWithResults)
      let modelNamesWithResults = new Set<string>(modelTemplatesWithResults.map(mt => mt.name))
      setModelNames(modelNamesWithResults);
      if ((selectedModel === undefined || !Array.from(modelNamesWithResults).includes(selectedModel)) && modelTemplatesWithResults.length > 0) {
        setSelectedModel(Array.from(modelNamesWithResults)[0])
    }
    }
  }, [modelTemplates, anomalyGraphDataMap, selectedModel])


  useEffect(() => {
    let tempPlots: { [modelName: string]: JSX.Element } = {}
    let newOrgRange = {...originalAxisRange}
    // Sort model templates into groups by name, and stitch the results together
    modelNames !== undefined && modelTemplates.every(mt => Object.keys(anomalyGraphDataMap).includes(mt.id)) && modelNames.forEach(mn => {
        let adr: AnomalyDetectionResult[] = []
        let templatesInGroup = modelTemplates.filter(mt => mt.name === mn)
        templatesInGroup.forEach(mt => {
          adr.push(...anomalyGraphDataMap[mt.id].filter(result => {
            if (templatesInGroup.length > 1) {
              if (mt.end_time !== undefined && mt.end_time !== null) {
                return result.end_time < mt.end_time && result.end_time >= mt.start_time
              } else {
                return result.end_time >= mt.start_time
              }
            }
            return true
          }))
        })
        // If data after stitching, draw plot
        if (adr.length > 0) {
          let feature = adr[adr.length -1 ].feature
          let props = plotifyData(feature as string, adr, alarms)
          newOrgRange[mn] = {xAxisRange: props.layout.xaxis?.range, yAxisRange: props.layout.yaxis?.range}
          props.layout.xaxis = {...props.layout.xaxis, ...(axisRange && axisRange.xAxisRange && {range: axisRange.xAxisRange})}
          props.layout.yaxis = {...props.layout.yaxis, ...(axisRange && axisRange.yAxisRange && {range: axisRange.yAxisRange})}
          tempPlots[mn] = (<Plot
              {...props}
              onClick={(e) => {
                if(onGraphClick !== undefined) {
                  onGraphClick(e)
                }
                navigateAlarmOnClick(e);
              }}
              key={`${device.id}-${feature}`}
              className={"tw-w-full"}
              onRelayout={handleRelayout}
            />
          )
        }
      }
    )
    setOriginalAxisRange(newOrgRange)
    setPlots(tempPlots)
  }, [modelNames, selectedGraphTime, alarms, anomalyGraphDataMap, device, modelTemplates, onGraphClick, axisRange])


  // Utils
  function hasMeta(object: any) {
    return 'meta' in object
  }

  function getMeta(object: any) {
    return object.meta
  }

  function mapModelNameToNiceName(modelName: string) {
    //Find latest version in group, and use that nicename to show to customer
    let modelsInGroup = modelTemplates.filter(mt => mt.name == modelName)
    if(modelsInGroup.length > 0){
      modelsInGroup.sort((m1, m2) => m2.version - m1.version)
      return modelsInGroup[0].nice_name ?? modelName
    }
    else return ""
  }

  //Handle axis changes from graph.
  const handleRelayout = (event: Readonly<PlotRelayoutEvent>) => {
    const x0 = event['xaxis.range[0]']
    const x1 = event['xaxis.range[1]']
    const y0 = event['yaxis.range[0]']
    const y1 = event['yaxis.range[1]']
    setAxisRange({xAxisRange: [x0,x1], yAxisRange: [y0,y1]})
  }

  const resetLayout = (modelName: string | undefined) => {
    if(modelName !== undefined && Object.keys(originalAxisRange).includes(modelName)) {
      setAxisRange(originalAxisRange[modelName])
    }
  }

  // Make plot
  const plotifyData = (feature: string, anomalyDetectionResults: AnomalyDetectionResult[], alarms: Alarm[]): PlotParams => {

    const plotData: Data[] = []

    const data = anomalyDetectionResults.sort((a, b) => {
      return a.end_time - b.end_time
    });

    /* Insert null values in data if gap is too large, so that graphs render with gaps*/

    let data_with_nulls: (AnomalyDetectionResult | null)[] = []

    for(let i = 1; i < data.length; i++){
      let a = data[i-1]
      let b = data[i]
      let dist = b.end_time - a.end_time

      if(i == 0){
        data_with_nulls.push(a)
      }
      if(dist > dataGapDist){
        data_with_nulls.push(null)
      }
      data_with_nulls.push(b)

    }

    /* Set up different graph data from anomaly results*/

    const times = data_with_nulls.map(adr => adr !== null ? formatDate((new Date(adr.end_time * 1000))) : null)
    const values = data_with_nulls.map(adr => adr?.value ?? null)

    let probability = data_with_nulls.map(adr => adr?.probability ?? null)

    const normalLower = data_with_nulls.map(adr => adr?.normal_lower ?? null)
    const normalUpper = data_with_nulls.map(adr => adr?.normal_upper ?? null)

    // Make sure we have both lower and upper, or neither.
    // (If upper is not there when lower is, remove lower, as it is a bit useless alone)
    for(let i = 0; i < normalUpper.length; i++){
      if(normalUpper[i] !== null && normalLower[i] === null){
        normalLower[i] = 0
      }
      if(normalLower[i] !== null && normalUpper[i] === null){
        normalLower[i] = null
      }
    }

    // Calculate max and min values for setting graph ranges and sizes of annotations etc.
    const normalLowerNumbers = normalLower.filter(v => typeof v === "number") as number[];
    const normalUpperNumbers = normalUpper.filter(v => typeof v === "number") as number[];
    const yNumbers = values.filter(v => typeof v === "number") as number[];
    const yUpperRange = Math.max(Math.max(...yNumbers), Math.max(...(normalUpperNumbers.length > 0 ? normalUpperNumbers : yNumbers)))
    const yLowerRange: number = feature === 'leq' ?  Math.min(Math.min(...yNumbers), Math.min(...(normalLowerNumbers.length > 0 ? normalLowerNumbers : yNumbers))) : 0 //Want graph y-axis to start at 0 if vibration, else set a margin

    const normalBandColor = 'rgba(159,226,16,0.8)'

    let showlegend = true;

    let dataLower = createDiscontinousGraphWithFill(times, normalLower, {
              name: "Normal lower",
              fill: 'tozeroy',
              fillcolor: 'transparent',
              marker: {
                color: 'green'
              },
              legendgroup: 'normal bands',
              showlegend
            } as PlotData)
    let dataUpper = createDiscontinousGraphWithFill(times, normalUpper, {
              fill: "tonexty",
              fillcolor: normalBandColor,
              marker: {
                color: 'green'
              },
              name: "Normal upper",
              legendgroup: 'normal bands',
              showlegend
            } as PlotData)

    //These need to be added in correct order for fill to work correctly for normal band data.
    // Disregards normal data if not cont series exists in both upper and lower
    for( let i = 0; i < dataLower.length; i++){
      let lower = dataLower[i]
      let upper = dataUpper[i]
      if (upper !== undefined && lower !== undefined){
        plotData.push(dataLower[i], dataUpper[i])
      }
    }

    plotData.push({
      x: times,
      y: values,
      type: 'scatter',
      mode: 'lines',
      marker: {color: 'black'},
      name: mapFeatureToCustomerFacing(feature),
    })

    showProbability ? plotData.push(...createDiscontinousGraphWithFill(times, probability,{
            type: 'scatter',
            mode: 'lines',
            yaxis: 'y2',
            fill: 'tozeroy',
            fillcolor: 'rgba(255, 0, 0, 0.1)',
            marker: {color: 'rgba(255, 0, 0, 0.2)'},
            name: "Anomaly Score",
            visible: probabilityVisible ? true : "legendonly",
        } as PlotData)) : console.log("Probability not visible")


    const yLowerRangeWithMargins = feature === 'leq' ? yLowerRange*0.75 : yLowerRange //Do not want margins at bottom of y-axis unless sound data
    const yUpperRangeWithMargins = yUpperRange*1.25 > 0.05 ? yUpperRange*1.25 : 0.5; //Give it som margins unless values are very low - then set absolute value to avoid that small changes in value seem large

    let setResolvedLegend = false
    let setActiveLegend = false
    const existingAlarmData: Data[] = alarms.map((pa, index) => {
        let shouldSetLegend = pa.resolved ? !setResolvedLegend : !setActiveLegend
        pa.resolved ? setResolvedLegend = true : setActiveLegend = true
        return {
          ...createPreviousAlarmXYAndText(pa, 100, yLowerRangeWithMargins, yUpperRangeWithMargins),
          legendgroup: !pa.customer_visible ? "Suggested Alarm" : pa.resolved ? 'resolvedAlarms' : 'activeAlarms',
          mode: 'lines+markers',
          marker: {color: 'rgba(255, 255, 255, 0)', size: 16},
          showlegend: shouldSetLegend,
          name: !pa.customer_visible ? "Suggested Alarm" : pa.resolved ? 'Resolved alarms' : 'Active alarms',
          line: {
            dash: 'dash',
            width: 4,
            color: !pa.customer_visible ? "blue" : pa.resolved ? 'green' : pa.alarm_type === "A" ? 'red' : 'orange'
          },
          meta: {
            'alarm_id': pa.id
          }
        }
      }
    )
    plotData.push(...existingAlarmData)

    if(addCustomPlotData !== undefined){
      addCustomPlotData(plotData, yLowerRange, yUpperRange)
    }

    const to = graphEndTime ?? new Date();
    const from = new Date(to.getTime() - simpleGraphTimeConverter(selectedGraphTime ?? "Last 3 days") * 24 * 60 * 60 * 1000)

    let layout: Partial<Layout> = {
        title: `Feature: ${mapFeatureToCustomerFacing(feature)}`,
        hovermode: hovermode,
        autosize: true,
        margin: {l: 68},
        xaxis: {
          type: 'date',
          range: [formatDate(from), formatDate(to)]
        },
        yaxis: {
          // margins!
          range: [yLowerRangeWithMargins, yUpperRangeWithMargins]
        },
      }

    if(showProbability){
      layout.yaxis2 = {
                    title: 'probability',
                    overlaying: 'y',
                    side: 'right',
                    range: [0, 1]
                }
    }

    if(addCustomPlotLayout !== undefined){
      addCustomPlotLayout(layout, yLowerRange, yUpperRange)
    }

    plotData.forEach(pd => (pd as PlotData)["connectgaps"] = false)

    return {
      data: plotData, layout: layout,
      config: {
        modeBarButtonsToRemove: ["zoom2d", "pan2d", "lasso2d"],
        displaylogo: false
      }
    }
  }

  //Action handlers
  const navigateAlarmOnClick = (event: Plotly.PlotMouseEvent) => {
    if (event.points.length === 1 && navigateToAlarmOnClick) {
      let data = event.points[0].data
      if (hasMeta(data)) {
        let meta: any = getMeta(data)
        if ('alarm_id' in meta) {
          navigate("/alarm/" + meta.alarm_id)
        }
      }
    }
  }

  const handleGraphUpdateAfterClick = (feature: string, data: Data | undefined | void): void => {
    //TODO: Implement this properly.
  }

  return (
    <div className={className}>
      <div
        className="tw-text-sm tw-font-medium tw-text-gray-500 tw-ml-10">
        <p className="tw-flex tw-inline-flex">Model</p>
        <ul className="tw-flex tw-inline-flex tw-flex-wrap tw-mb-px tw-ml-5 tw-mt-4">
          {modelNames !== undefined && modelNames.size > 0 ? Array.from(modelNames).map(modelName => (
            <li className="tw-mr-2" key={modelName}>
              <Button type="button" size="small" onClick={() => {
                setSelectedModel(modelName)
              }}
                      variant="secondary"
                      styles={modelName !== selectedModel ? "tw-bg-transparent" : "tw-text-black"} key={modelName}>
                {mapModelNameToNiceName(modelName)}
              </Button>
            </li>)) : ""}
        </ul>
        {(selectedGraphTime !== undefined && onUpdateGraphTime !== undefined) &&
          <div className="tw-flex tw-inline-flex tw-text-center tw-right-20 tw-mt-4 tw-float-right">


            <div role="status" className={loading ? "tw-visible" : "tw-hidden"}>
              <span className="tw-sr-only tw-mr-2">Loading...</span>
              <svg aria-hidden="true"
                   className="tw-inline tw-w-8 tw-h-8 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-gray-600 dark:tw-fill-gray-300 tw-mr-4"
                   viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
            </div>


            <Button styles={"tw-mr-4"} type={"button"} size={"small"} onClick={() => resetLayout(selectedModel)}>
              Reset Zoom
            </Button>
            <SimpleDateSelect selectedTime={selectedGraphTime} setSelectedTime={(selectedTime) => {
              setAxisRange({ "xAxisRange": undefined, "yAxisRange": undefined })
              onUpdateGraphTime(selectedTime)
            }} />
          </div>}

      </div>
      {selectedModel !== undefined && plots !== undefined && (Object.keys(plots).includes(selectedModel)) ? plots[selectedModel] :
        (loading ?
          <p className="tw-flex tw-text-center tw-m-10">Loading graph data...</p>
          : <p className="tw-flex tw-text-center tw-m-10">No anomaly detection results available for this model</p>)}
    </div>
  )


}
