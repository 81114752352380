import Titlebar from "../../components/Titlebar"
import { TempState } from "../../types/componentTypes"
import { useEffect, useState } from "react"
import {
  Asset,
  Building,
  Device,
  DeviceDeployment,
  DeviceStatus,
  ModelTemplate,
  Organization,
  Room,
} from "../../types/dataTypes"
import { DeviceStatusWithInfo } from "../../types/componentTypes"
import { getActiveModelTemplates, getDeviceConditionCached } from "../../api/api"
import Alarm from "./components/Alarm"
import PaginatedAlarms from "./components/PaginatedAlarms"
import GenericFilter from "../../components/GenericFilter"
import { Selectable } from "../../components/GenericFilter/GenericFilter"
import { getModelTemplates } from "../../api/api-ts"


type MainState =
  {
    state: TempState
  }

function sfc32(a: number, b: number, c: number, d: number) {
  /** Pseudo-random number generator, seeded with four numbers
   * https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript**/
  return function() {
    a >>>= 0
    b >>>= 0
    c >>>= 0
    d >>>= 0
    var t = (a + b) | 0
    a = b ^ b >>> 9
    b = c + (c << 3) | 0
    c = (c << 21 | c >>> 11)
    d = d + 1 | 0
    t = t + d | 0
    c = c + t | 0
    return (t >>> 0) / 4294967296
  }
}

function cyrb128(str: string): number[] {
  /** Create seed from string, makes seed very different for similar strings
   * https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript**/
  let h1 = 1779033703, h2 = 3144134277,
    h3 = 1013904242, h4 = 2773480762
  for (let i = 0, k; i < str.length; i++) {
    k = str.charCodeAt(i)
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067)
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233)
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213)
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179)
  }
  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067)
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233)
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213)
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179)
  h1 ^= (h2 ^ h3 ^ h4)
  h2 ^= h1
  h3 ^= h1
  h4 ^= h1
  return [h1 >>> 0, h2 >>> 0, h3 >>> 0, h4 >>> 0]
}


export default function AlarmDashboard({ state }: MainState) {
  const [devices, setDevices] = useState<Device[]>([])
  const [deviceDataLoading, setDeviceDataLoading] = useState(true)
  const [deviceConditions, setDeviceConditions] = useState<DeviceStatus[]>([])
  const [search, setSearch] = useState<string>()
  const [anomalyProbabillityVisible, setAnomalyProbabillityVisible] = useState<boolean>(false)
  const [deviceStatusWithInfo, setDeviceStatusWithInfo] = useState<DeviceStatusWithInfo[]>([])
  const [filteredDeviceStatusWithInfo, setFilteredDeviceStatusWithInfo] = useState<DeviceStatusWithInfo[]>([])
  const [randomSeedDate, setRandomSeedDate] = useState<Date>(new Date())

  //Filter state

  const [filteredOrgs, setFilteredOrgs] = useState<Organization[]>()
  const [filteredBuildings, setFilteredBuildings] = useState<Building[]>()
  const [filteredRooms, setFilteredRooms] = useState<Room[]>()

  useEffect(() => {
    /** Update devices from state to include only devices that was active at selected time in a model template **/
    const getActiveCMDevices = async (devices: Device[]) => {
      // Get active templates
      const templates = await getModelTemplates([], [], [],
        undefined, undefined, randomSeedDate, undefined)
      let activeDeviceIds: string[] = []
      templates.forEach((t: ModelTemplate) => {
        t.devices?.forEach((d: Device) => {
          if (!activeDeviceIds.includes(d.id)) {
            activeDeviceIds.push(d.id)
          }
        })
      })

      return devices.filter(d => activeDeviceIds.includes(d.id))
    }
    const fetchDevices = async () => {
      if (state.deviceDeployments?.length ?? 0 > 0) {
        try {
          let data = await getActiveCMDevices(state.devices)
          // Remove devices that don't have an organization
          setDevices(data)
        } catch (err) {
          console.log(err)
        }
      }
    }
    setFilteredOrgs(state.organizations.sort((a, b) => a.name.localeCompare(b.name)))
    setFilteredRooms(state.rooms?.sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : -1))
    setFilteredBuildings(state.buildings?.sort((a, b) => {
        let aval = a.name ?? a.address
        let bval = b.name ?? b.address
        return aval.localeCompare(bval)
      }),
    )
    fetchDevices()
  }, [state])

  useEffect(() => {
    const fetchDeviceConditions = async () => {
      const result: DeviceStatus[] = await getDeviceConditionCached()
      setDeviceConditions(result.filter(dc => dc.status === "CRITICAL" || dc.status === "WARNING"))
    }
    devices.length > 0 && fetchDeviceConditions()
  }, [devices])

  useEffect(() => {
    setDeviceDataLoading(true)
    let gatheredDeviceInfo: DeviceStatusWithInfo[] = []
    const DEMO_ORG_ID = "e6efa842-7186-4748-a712-a37a504b7958"
    //find devices that are not in condition list
    let devicesWithoutDemo = devices.filter(d => d.organization_id !== DEMO_ORG_ID)
    devicesWithoutDemo.sort((a, b) => a.id.localeCompare(b.id))
    let seed = cyrb128(randomSeedDate.toLocaleDateString())
    let dateSeededRandomNumberGenerator = sfc32(seed[0], seed[1], seed[2], seed[3])
    if (devicesWithoutDemo.length > 0 && deviceConditions.length > 0) {
      // Create list with random but unique numbers from length of device list
      let selection = Array.from(new Set(Array.from({ length: 20 }, (_, n) => Math.floor(dateSeededRandomNumberGenerator() * devicesWithoutDemo.length))).values())
      // Create fake deviceConditions from devices not in condtions
      let randomlyPickedDevices: DeviceStatusWithInfo[] = selection.map(n => {
          let selectedDevice: Device = devicesWithoutDemo[n]

          let deviceDeployment: DeviceDeployment | undefined = state.deviceDeployments?.find((d, _) => {
            return (d.device_id === selectedDevice.id && (d.end_time === undefined || d.end_time == null || d.end_time > randomSeedDate.getTime() / 1000))
          })
          let room: Room | undefined = state.rooms?.find(r => r.id === deviceDeployment?.room_id)
          let building: Building | undefined = room !== undefined ? state.buildings?.find(b => b.id === room?.building_id) : undefined
          let temporg = state.organizations.find(o => o.id === selectedDevice.organization_id)

          let deviceStatus: DeviceStatus = {
            device: selectedDevice, device_deployment: deviceDeployment, status: "WARNING", status_text: "WARNING",
          }
          let deviceStatusWithInfo: DeviceStatusWithInfo = {
            building: building, deviceDeployment: deviceDeployment, deviceStatus: deviceStatus, organization: temporg, room: room,
          }
          return deviceStatusWithInfo
        },
      )
      gatheredDeviceInfo = gatheredDeviceInfo.concat(randomlyPickedDevices.filter(d => !deviceConditions.map(dc => dc.device.id).includes(d.deviceStatus.device.id) && d.deviceDeployment !== undefined))

      deviceConditions.forEach(dc => {
          let room: Room | undefined = state.rooms?.find(r => r.id === dc.device_deployment?.room_id)
          let building: Building | undefined = room !== undefined ? state.buildings?.find(b => b.id === room?.building_id) : undefined
          let deviceDeployment = state.deviceDeployments?.find(dd => dd.id === dc.device_deployment?.id)
          let temporg = state.organizations.find(o => o.id === dc.device.organization_id)
          let deviceWithInfo: DeviceStatusWithInfo = {
            "deviceStatus": dc,
            "deviceDeployment": deviceDeployment,
            "room": room,
            "building": building,
            "organization": temporg,
          }
          gatheredDeviceInfo.push(deviceWithInfo)
        },
      )

      gatheredDeviceInfo = gatheredDeviceInfo.filter(r => {
        /** Remove muted buildings from list **/
        if (r.building && r.building.meta) {
          if (Object.keys(r.building.meta).includes("muted_to") && r.building.meta["muted_to"] * 1000 > new Date().getTime()) {
            return false
          }
        }
        return true
      })

      setDeviceStatusWithInfo(gatheredDeviceInfo)
    }

  }, [deviceConditions, randomSeedDate, devices, state])


  useEffect(() => {
    if (search !== undefined) {
      const timeOutId = setTimeout(() => deviceStatusWithInfo.length > 0 && setFilteredDeviceStatusWithInfo(
        filterDataBySearch(),
      ), 1000)
      setDeviceDataLoading(false)
      return () => clearTimeout(timeOutId)
    } else {
      deviceStatusWithInfo.length > 0 && setFilteredDeviceStatusWithInfo(deviceStatusWithInfo.sort((a, b) => {
        if (a.building && b.building) {
          return a.building.id.localeCompare(b.building.id)
        }
        return a.deviceStatus.device.id.localeCompare(b.deviceStatus.device.id)
      }))
      setDeviceDataLoading(false)
    }
  }, [search, deviceStatusWithInfo, filteredOrgs, filteredBuildings, filteredRooms])

  const filterDataBySearch = () => {
    const searchRegex = new RegExp(search ?? "", "i") // 'i' means ignore case
    let filteredRows = deviceStatusWithInfo.filter(row => {
      let unlikelySearchString = "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH"
      return (
        searchRegex.test(row.room?.name ?? unlikelySearchString) ||
        searchRegex.test(row.room?.nice_name ?? unlikelySearchString) ||
        searchRegex.test(row.building?.address ?? unlikelySearchString) ||
        searchRegex.test(row.building?.name ?? unlikelySearchString) ||
        searchRegex.test(row.deviceDeployment?.assets[0].name ?? unlikelySearchString) ||
        searchRegex.test(row.deviceDeployment?.assets[0].id ?? unlikelySearchString) ||
        searchRegex.test(row.deviceStatus.device.id) ||
        searchRegex.test(row.deviceStatus.device.serial) ||
        searchRegex.test(row.organization?.name ?? unlikelySearchString)
      )
    })

    filteredRows = filteredRows.filter(r => filteredOrgs?.map(o => o.id).includes(r.organization?.id ?? "YOLO"))
    filteredRows = filteredRows.filter(r => filteredBuildings?.map(o => o.id).includes(r.building?.id ?? "YOLO"))
    filteredRows = filteredRows.filter(r => filteredRooms?.map(o => o.id).includes(r.room?.id ?? "YOLO"))

    filteredRows = filteredRows.sort((a, b) => {
      if (a.building && b.building) {
        return a.building.id.localeCompare(b.building.id)
      }
      return a.deviceStatus.device.id.localeCompare(b.deviceStatus.device.id)
    })
    return filteredRows
  }

  return (
    <>
      <Titlebar headline="Manage alarms" />
      <div className="tw-flex tw-flex-col tw-items-center tw-mt-2">
        <div className="tw-flex tw-flex-row tw-space-x-5">
          <GenericFilter title={"Organizations"} setSelected={(orgs: Organization[]) => {
            setFilteredOrgs(orgs)
          }} mapAvailableToSelectable={function(o: Organization) {
            return { id: o.id, name: o.name }
          }} selected={filteredOrgs} available={state.organizations} />

          <GenericFilter title={"Buildings"} setSelected={(buildings: Building[]) => {
            setFilteredBuildings(buildings)
          }} mapAvailableToSelectable={function(b: Building) {
            return { id: b.id, name: b.name ?? b.address }
          }} selected={filteredBuildings} available={state.buildings} />

          <GenericFilter title={"Rooms"} setSelected={(rooms: Room[]) => {
            setFilteredOrgs(rooms)
          }} mapAvailableToSelectable={function(r: Room) {
            return { id: r.id, name: r.name }
          }} selected={filteredRooms} available={state.rooms} />
        </div>
      </div>
      <div className={"tw-flex tw-flex-col tw-items-center"}>
        <input type="text" value={search} placeholder={"Search for anything.."}
               onChange={e => setSearch(e.target.value)}
               className={"tw-mx-10 tw-mt-10 tw-text-center tw-h-[60px] tw-rounded-full tw-w-1/2"} />
        <div className={"tw-flex-row tw-mt-2"}>
          <div className={"tw-inline-block tw-mr-2"}>
            Show Probabillity:
            <input className={"tw-ml-2"} type={"checkbox"}
                   onChange={() => setAnomalyProbabillityVisible(!anomalyProbabillityVisible)}
                   checked={anomalyProbabillityVisible} />
          </div>
          <div className={"tw-inline-block tw-mr-2 tw-ml-2"}>
            Select date for random devices:
            <input className={"tw-ml-2"} type={"date"} placeholder="Select Date"
                   defaultValue={randomSeedDate.toISOString().substring(0, 10)}

                   onChange={event => {
                     event.target.valueAsDate !== null && setRandomSeedDate(event.target.valueAsDate)
                   }} />
          </div>
        </div>
        {(deviceDataLoading === undefined || deviceDataLoading) ? <div> Loading data... </div> :
          <PaginatedAlarms devicesPerPage={5} anomalyProbabillityVisible={anomalyProbabillityVisible}
                           deviceConditions={filteredDeviceStatusWithInfo} />}
      </div>
    </>
  )
}